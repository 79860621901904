<template>
  <div>
    <header>
      <ul>
        <li>
          <img @click="tui" src="../../icon/返回.png" class="img1" alt="" />
        </li>
        <li class="li2">
          <van-tabs v-model="active" title-active-color="#ee0a24">
            <van-tab title="我的提问">
              <div class="main_my">
                <div
                  class="sss"
                  v-for="(item, index) in my_list"
                  :key="index"
                >
                  <div class="k1"></div>
                  <div class="yi">
                    <div class="tu">
                      <img :src="item.avatar" alt="" />
                    </div>
                    <div class="chan">
                      {{ item.username ? item.username : '未知' }}
                    </div>
                  </div>
                  <div class="hao">{{ item.title ? item.title : '标题' }}</div>
                  <div class="hao1">{{ item.content }}</div>
                  <div class="hao2">
                    <span>{{ item.answer_count }}</span
                    >个回答
                  </div>
                  <div class="hui"><div class="hui_1"></div></div>
                </div>
              </div>
            </van-tab>
            <van-tab title="全部问答">
              <div class="sss1">
                <div
                  class="sss"
                  v-for="(item, index) in main_list"
                  :key="index"
                >
                  <div class="k1"></div>
                  <div class="yi">
                    <div class="tu">
                      <img :src="item.avatar" alt="" />
                    </div>
                    <div class="chan">
                      {{ item.username ? item.username : '未知用户' }}
                    </div>
                  </div>
                  <div class="hao">{{ item.title ? item.title : '标题' }}</div>
                  <div class="hao1">{{ item.content }}</div>
                  <div class="hao2">
                    <span>{{ item.answer_count }}</span
                    >个回答
                  </div>
                  <div class="hui">
                    
                  </div>
                </div>
              </div>
            </van-tab>
          </van-tabs>
        </li>
        <li>
          <img src="../../icon/更多.png" alt="" />
        </li>
      </ul>
    </header>
    <div class="fuqui" @click="tiwen">提问</div>
    <van-tabbar
      v-model="activee"
      active-color="#ee0a24"
      inactive-color="#000"
      class="www"
    >
      <van-tabbar-item icon="wap-home-o" @click="shouye">首页</van-tabbar-item>
      <van-tabbar-item class="daohang2" @click="kebiao1">
        <img
          src="../../icon/kb_m.png"
          alt=""
          class="kebiao"
        />课表</van-tabbar-item
      >
      <van-tabbar-item icon="comment-o">问答</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { get_wd_allMsg,get_myWT } from '@/api/QA.js'
import tool from '@/utils/tools.js'
export default {
  data() {
    return {
      list: ['我的问答', '全部问答'],
      active: 1,
      activee: 2,
      main_list: [],
      my_list:[],
      lesson_id:'',
      class_id:'',
      student_id:'',
      class_name:'',
    }
  },
  mounted() {
    this.lesson_id = this.$route.query.lesson_id
    this.class_id = this.$route.query.class_id
    this.student_id = this.$route.query.student_id
    this.class_name = this.$route.query.class_name
    this.get_all_msg()
    this.get_my_msg()
  },
  methods: {
    //我的提问
    get_my_msg(){
      var params = {
        page:1,
        limit:10,
        lesson_id: this.$route.query.lesson_id
      }
      get_myWT(params).then(res=>{
        this.my_list = res.result.list
      })
    },
    //全部提问
    get_all_msg() {
      var params = {
        page: 1,
        limit: 10,
        lesson_id: this.$route.query.lesson_id,
      }
      get_wd_allMsg(params).then((res) => {
        this.main_list = res.result.list
      })
    },
    tui() {
      if (tool.isInApp()) {
        if (tool.isIosApp()) {
          window.webkit.messageHandlers.vue_backApp.postMessage('app')
        } else {
          this.$jsbridge.callHandler(
                  'vue_backApp'
                  , '返回'
                  , function(responseData) {
                    // android 返参数接受
                  }
          )
        }
      } else {
        this.$router.go(-1)
      }
    },
    shouye() {
      this.$router.push({
        path: '/banji',
        query: {
          lesson_id: this.lesson_id,
          class_id: this.class_id,
          student_id: this.student_id,
          class_name: this.class_name
        }
      })
    },
    kebiao1() {
      this.$router.push({
        path: '/kebiao',
        query: {
          lesson_id: this.lesson_id,
          class_id: this.class_id,
          student_id: this.student_id,
          class_name: this.class_name
        }
      })
    },
    tiwen() {
      this.$router.push()
      this.$router.push({
        path:'/tiwen',
        query:{
          lesson_id:this.$route.query.lesson_id
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.main_my{
  width: 140%;
      position: absolute;
    left: -185px;
    top: 10px;
}
.fuqui {
  width: 100px;
  height: 100px;
  background: red;
  line-height: 100px;
  text-align: center;
  position: absolute;
  bottom: 160px;
  right: 60px;
  border-radius: 50px;
  font-size: 30px;
  color: rgb(255, 255, 255);
  box-shadow: 0px 0px 15px 0px #ccc;
  border: none;
  letter-spacing: 2px;
}
.www {
  width: 100%;
  height: 6vh;
  // background: red;
  // 80.5
}
.hui {
  width: 100%;
  //   margin-left: -20px;
  height: 20px;
  background: rgb(247, 247, 247);
  //   margin-left: -174px;
  position: relative;
}
// .hui_1{
//   position: absolute;
//   right: -100px;
//   top:0;
//   background: red;
//   width: 200px;
//   height: 20px;
//   z-index: 999;
// }
.hao {
  width: 100%;
  text-align: left;
  margin-top: 10px;
  margin-left: 30px;
}
.hao1 {
  width: 100%;
  text-align: left;
  margin-top: 20px;
  margin-left: 30px;
  font-size: 26px;
}
.hao2 {
  width: 100%;
  height: 50px;
  text-align: left;
  margin-top: 20px;
  margin-left: 30px;
  font-size: 20px;
  color: rgb(189, 189, 189);
}
.chan {
  line-height: 70px;
  font-size: 26px;
  margin-left: 16px;
  color: rgb(189, 189, 189);
}
.k1 {
  width: 100%;
  height: 20px;
}
.yi {
  width: 100%;
  height: 70px;
  margin-left: 20px;
  // margin-top: 20px;
  //   background: rgb(0, 255, 55);
  display: flex;
}
.tu {
  width: 70px;
  height: 70px;
  background: seagreen;
  border-radius: 50px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 50px;
  }
  // margin-top: 30px;
}
.sss1 {
  width: 742px;
  margin-top: 10px;
  margin-left: -173px;
  height: 83vh;
  overflow: hidden;
  overflow-y: scroll;

  // background: salmon;
}
.sss {
  width: 100%;
  margin-top: 10px;
  //   height: 83vh;
  overflow: hidden;

  // background: rgb(20, 146, 37);
}
.wen {
  width: 9.666667rem;
  height: 200px;
  background: red;
  margin-left: -160px;
  margin-top: 10px;
}
.kebiao {
  position: fixed;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // top: -30px;
  width: 85px;
  height: 85px;
  // border: 1px solid #000;
  // padding: 4spx;
  // width: 80px;
  // height: 80px;
  background-color: red;
  border-radius: 50%;
  bottom: 0.66667rem;
  left: 334px;
  z-index: 666;
  box-shadow: 0.01rem 0rem 0.1rem 5px white;
}
.daohang2 {
  display: flex;
  // flex-direction: column;
  justify-content: center;
  // align-items: center;
  // position: relative;
  padding-top: 34px;
  // padding-right: 20px;
}
.li2 {
  width: 400px;
  height: 80px;
  // background: red;
  //   overflow: hidden;
  //   line-height: 50px;
}
.cha {
  width: 160px;
  height: 60px;
  border: 1px solid rgb(253, 253, 253);
  margin-left: 16px;
  border-radius: 60px;
  line-height: 60px;
  display: flex;
  justify-content: space-around;
}
header {
  width: 100%;
  height: 110px;
  background: rgb(255, 255, 255);
  border-bottom: 1px solid rgb(207, 200, 200);
  ul {
    width: 100%;
    height: 60%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: rgb(0, 0, 0);
    font-size: 40px;
    text-align: center;
    li {
      width: 200px;
      height: 60px;
      margin-top: 55px;
    }
  }
}
</style>
<style lang="scss">
  .van-tab__pane, .van-tab__pane-wrapper{
    position: relative;
    width: 141%;
  }
</style>
